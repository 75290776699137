<template>
    <v-dialog
      :model-value="dialog"
      scrollable
      persistent 
      width="800">
      <v-card>
        <v-card-title
          primary-title
        >
          {{ subClassification.name }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container 
            fluid>
            <v-autocomplete
              v-model="selectedSkills"
              :items="fillSelectedList"
              item-title="name"
              :label="$t(`profil.${this.lang}.section_my_skills`)"
              chips
              multiple
            >
              <template v-slot:item="data">
                <v-list-item>
                  <div class="d-flex align-center">
                    
                  <v-container>
                    <v-row>
                      <v-list-item-action>
                        <v-checkbox 
                          :input-value="isSelected({
                          data: data.item,
                          selected: selectedSkills, 
                          deselected: deselectedSkills, 
                          userId: basicData.userID
                          })"
                          @change="selectSkill(data.item)">
                        </v-checkbox>
                      </v-list-item-action>
                      <v-list-item-title style="transform: translateY(+15px);" >{{data.item.raw.name}}</v-list-item-title>
                    </v-row>
                  </v-container>
                  <v-tooltip top max-width="500" open-delay="300">
                    <template v-slot:activator="{ props }">
                      <div v-bind="props" v-if="data.item.raw.description">
                        <v-icon>mdi-information</v-icon>
                      </div>
                    </template>
                    <span>{{ data.item.raw.description }}</span>
                  </v-tooltip>
                  </div>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-container>

          <v-container  v-for="item in selectedSkills"
            :key="item.id"
            fluid >
            <v-toolbar
              flat>
              <v-btn
                color="red" 
                @click="deleteSelected(item)"
                icon>
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
              <v-toolbar-title>{{item.name}}</v-toolbar-title>
              <v-spacer/>
              <v-tooltip
                  top
                  max-width="500"
                  open-delay="1000">
                <template v-slot:activator="{ props }">
                  <div v-bind="props">
                    <v-rating
                      background-color="blue"
                      color="primary"
                      dense
                      full-icon="mdi-checkbox-blank"
                      empty-icon="mdi-checkbox-blank-outline"
                      v-model="item.level"
                      :length="item.rating.skale"
                      size="25">
                    </v-rating>
                  </div>
                </template>
                <div>
                  <div
                    v-for="(element, key) in item.rating.allowedValues "
                    :key="element">
                    <span>{{ key + ": "}}</span>
                    <span>{{ element }}</span>
                  </div>
                </div>
              </v-tooltip>
            </v-toolbar>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            
            @click="closeDialog()">
            {{$t(`profil.${this.lang}.cancel`)}}
          </v-btn>
          <v-btn
            color="primary"
            
            @click="saveSkills()">
            {{$t(`profil.${this.lang}.save`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

  export default {
    props: {
      dialog: Boolean,
      subClassification:Object
    },
    data () {
      return {
        selectedSkills:[],
        deselectedSkills:[],
        selectedRatings: [],
        classificationId:0,
        initial:false,
        getBody: {
          userID:"",
          classificationID:"",
          code: ""
        }
      }
    },
    computed: {
      ...mapState({
        basicData: state => state.users.myUser,
        skillSelection: state => state.users.skillSelection,
        lang: state => state.general.profilLang,
      }),
      fillSelectedList() {
        let that = this;
        if (!that.initial && null != that.skillSelection.availableSkills) {
          that.initial = true;
          for(let item in that.skillSelection.availableSkills) {
            if(0 < that.skillSelection.availableSkills[item].level) {
              that.selectedSkills.push(that.skillSelection.availableSkills[item]);
            }
          }
        }
        if(that.skillSelection.availableSkills != null){
          that.skillSelection.availableSkills.sort(function(a, b) {
            let varA = a.relevance;
            let varB = b.relevance;
            return (varA < varB) ? -1 : (varA > varB) ? 1 : 0;
          });
          //sort((a, b) => a.name > b.name ? 1 : -1);
        }
        return that.skillSelection.availableSkills;
      }
    },
    created() {
      this.getBody.userID = this.basicData.userID;
      this.getBody.classificationID = this.subClassification.id;
      this.getBody.code = this.$i18n.locale;
      this.getAvailableSkills(this.getBody);
    },
    methods: {
      ...mapActions('users', ['saveMySkills', 'deleteMySkills','getAvailableSkills']),
      closeDialog() {
        this.$emit("update-expandSkills", !this.dialog);
      },
      isSelected({ data, selected, deselected, userId }) {
        if (data.level > 0 && !selected.includes(data)) {
          data.userId = userId;
          selected.push(data);
          const indexDeselect = deselected.indexOf(data);
          if (indexDeselect >= 0) {
            deselected.splice(indexDeselect, 1);
          }
        }
        return selected.includes(data);
      },
      selectSkill(item) {
        const formattedItem = this.formatSkill(item);
        let indexSelect = this.selectedSkills.indexOf(item);
        let indexDeselect = this.deselectedSkills.indexOf(item);
        if(indexSelect < 0) {
          formattedItem.level = 1;
          formattedItem.userId = this.basicData.userID;
          this.selectedSkills.push(formattedItem);
          if(indexDeselect >= 0){
            this.deselectedSkills.splice(indexDeselect, 1);
          }
        } else {
          item.level = 0;
          item.userId = null;
          this.selectedSkills.splice(indexSelect, 1);
          if(indexDeselect < 0){
            this.deselectedSkills.push(item);
          }
        }
      },
      saveSkills() {
        this.saveMySkills({newMySkills : this.selectedSkills, deleteMySkills : this.deselectedSkills});

        this.$emit("update-expandSkills", !this.dialog);
      },
      deleteSelected(item) {
        let index = this.selectedSkills.indexOf(item);
        let indexDeselect = this.deselectedSkills.indexOf(item);
        if(index > -1) {
          item.level = 0;
          this.selectedSkills.splice(index, 1)
          if(indexDeselect < 0){
            this.deselectedSkills.push(item);
          }
        }
      },
      formatSkill(item) {
        return {
          skillId: item.raw.skillId,
          name: item.title,
          code: item.raw.code,
          currentClassifications: item.raw.currentClassifications,
          level: item.level,
          userId: item.userId,
          rating: item.raw.rating,
          description: item.raw.description,
          relevance: item.raw.relevance
        }
      }
    }
  }
</script>
