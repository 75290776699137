<template>
  <v-container>
      <v-navigation-drawer
        v-model="drawer"
        app
        height="100%"
      >
        <v-list-item>
          <v-list-item-avatar>
            <v-img :src="avatarImage"></v-img>
          </v-list-item-avatar>

          <div class="d-flex align-center justify-space-between">
            <v-list-item-title>{{fullname}}</v-list-item-title>
            <v-menu offset-y>
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  elevation="0"
                  icon
                >
                <v-icon>mdi-cog</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="logout()">
                  <div class="d-flex align-center">
                    <v-list-item-icon class="mr-4">
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{$t('i18n_logout')}}</v-list-item-title>
                  </div>
                </v-list-item>
                <v-list-item @click="changePassword()">
                  <div class="d-flex align-center justify-space-between">
                    <v-list-item-icon class="mr-4">
                      <v-icon>mdi-key-change</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{$t('i18n_change_password')}}</v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-list-item>
        <v-divider></v-divider>
        <v-list
          @update:selected="onPageSelected" 
        >
            <v-list-item 
              v-for="(item, i) in visiblePages"
              :key="i"
              :value="i"
              link
              @click="onPageSelected(item)">
                <div class="d-flex align-center">
                  <v-list-item-icon class="mr-10">
                    <v-icon>{{item.icon}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{$t(item.name)}}</v-list-item-title>
                </div>
            </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar
        app
        color="white"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-btn @click="goHome()" icon="mdi-home"/>
        <v-spacer></v-spacer>
        <v-img src="../assets/logo_aqs.png" contain apsect-ration="1.7" max-height="64" max-width="207"></v-img>
        <v-spacer></v-spacer>
                  <div  class="d-flex align-center">
                    <small class="mr-2">DE</small>
                    <v-switch  v-model="profileLanguage"
                               true-value="en"
                               false-value="de"
                               style="transform: translateY(+9px);"></v-switch>
                    <small class="ml-2">EN</small>
                  </div>
        <div class="mx-auto bg-info" style="width: 100px;"></div>
        <span>Version: {{ this.metaData.version }}</span>
      </v-app-bar>

      <v-main class="content">
         
          <ProfilePage v-if="selectedPage===0"/>
          <TemplatePage v-if="selectedPage===1"/>
          <RolePage v-if="selectedPage===2"/>
          <SearchPage v-if="selectedPage===3"/>
          <ConfigurationPage v-if="selectedPage===4"/>          
          <PublicationOverviewPage v-if="selectedPage===5"/>
          <ComparePage v-if="selectedPage===6"/>
          
          <ErrorPage v-if="selectedPage!==0 && selectedPage!==1 && selectedPage!==2 && selectedPage!==3 && selectedPage!==4 && selectedPage!==5 && selectedPage!==6"/>
      </v-main>
      <!--<MyFooter :selectedPage="selectedPage"/>-->
        <ChangePassword :dialog="passwordDialog" @close-dialog="changePassword"/>
      </v-container>
</template>

<script>
// @ is an alias to /src
import ProfilePage from './ProfilePage.vue';
import TemplatePage from './TemplatePage.vue';
import ErrorPage from './ErrorPage.vue';
import ConfigurationPage from './ConfigurationPage.vue';
import RolePage from './RolePage.vue';
import SearchPage from './SearchPage.vue';
import PublicationOverviewPage from './PublicationOverviewPage.vue'
//import MyFooter from '../components/customItems/Footer.vue'
import ChangePassword from '../components/customDialogs/ChangePassword.vue';
import ComparePage from './ComparePage.vue'

import { mapState, mapActions } from "vuex";
import { toValue } from 'vue';

export default {
  name: 'home',
  data () {
    return {
      drawer: false,
      selectedPage: 0,
      selectedListItem:0,
      isEditable: false,
      passwordDialog: false,
      profileLanguage:""
    }
  },
  computed: {
    ...mapState({
      me: state => state.account.user,
      pages:state =>state.general.visiblePages,
      myPrivileges: state => state.account.myPrivileges,
      selectedPageEditable: state => state.general.selectedPageEditable,
      isPhone: state => state.general.isPhone,
      metaData: state => state.meta.meta // Meta data
    }),
    fullname() {
      if (this.me) {
        return this.me.firstname + " " + this.me.lastname;
      }
      return "";
    },
    avatarImage() {
      if (this.me) {
        return this.me.image != null ? this.me.image : "";
      }
      return "";
    },
    visiblePages() {
      let result = [];
      for(var i in this.pages) {
        if(this.myPrivileges[this.pages[i].read] || this.myPrivileges[this.pages[i].edit]) {
          result.push(this.pages[i]);
        }
      }
      return result;
    },
  },
  watch: {
    profileLanguage: function (val) {
      // Update the URL
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, lang: val }
      });

      // Update the language and save to localStorage
      this.$i18n.locale = val;
      localStorage.setItem('preferredLanguage', val);

      this.setProfilLang(val);
      this.getUserById({ userId: this.me.userID, language: val });
    }
  },
  created() {
    const storedLang = localStorage.getItem('preferredLanguage');
    const lang = this.$route.query.lang || storedLang || this.$i18n.locale;

    this.profileLanguage = lang;
    this.$i18n.locale = lang;


    this.setProfilLang(lang);
    this.getMyPrivileges();
    this.getMetaData();
  },
  methods: {
    ...mapActions('account', ['getMyPrivileges', 'logout']),
    ...mapActions('general', ['setPageEditable']),
    ...mapActions('meta', ['getMetaData']),
    ...mapActions('users', ['getUserById']),
    ...mapActions('general', ['setProfilLang']),
    getPermission(data) {
      return this.myPrivileges[data];
    },
    changePassword() {
      this.passwordDialog = !this.passwordDialog
    },
    onPageSelected(pageItem) {
      this.selectedListItem = pageItem;
      var page = this.visiblePages[this.selectedListItem];
      if(null != page) {
        this.setPageEditable(this.myPrivileges[page.edit]);
        this.selectedPage = page.id;
      } else if(0 == this.visiblePages.length){
        this.selectedPage = null;
      }
    },
    goHome() {
      this.selectedPage = 0;
    },
  },
  components: {
    ProfilePage,
    ConfigurationPage,
    RolePage,
    SearchPage,
    TemplatePage,
    //MyFooter,
    ErrorPage,
    PublicationOverviewPage,
    ChangePassword,
    ComparePage
  }
      }
</script>


<style>
  .banner {
    background: url(../assets/aqs-main-logo.jpg) no-repeat;
    background-size: 100% 100%;
  }
  @media only screen and (min-width: 600px) {
    .footer {
      left:inherit !important;
      right:inherit !important;
    }
    .content {
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
    }
    .custom-scroll {
    max-height: 51.5rem; 
    overflow-y: auto;
    overflow-x: hidden;
    }
    .custom-scroll::-webkit-scrollbar-track {
      border: 1px solid rgb(48, 35, 35);
      border-radius: 8px;
      padding: 1px 0;
      background-color: #7e7e7e;
    }
    
    .custom-scroll::-webkit-scrollbar {
      width: 7px;
    }

    .custom-scroll::-webkit-scrollbar-thumb {
      border-radius: 8px;
      box-shadow: inset 0 0 6px rgba(250, 250, 250, 0.3);
      background-color: #cacaca;
      border: 1px solid rgb(255, 255, 255);
    }
  }
  
</style>
