<template>
  <div class="text-center">
    <v-dialog
      :model-value="dialog"
      persistent
      width="800"
    >
      <v-card>
        <v-card-title
          primary-title
        >
          {{$t(`profil.${this.lang}.projects`)}}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="firstValid">
              <v-row>
                <v-col cols="9">
                  <v-text-field
                      :label="$t(`profil.${this.lang}.customer`) + '*'"
                      v-model="selectedData.customer"
                      :rules="requiredRules"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      :label="$t(`profil.${this.lang}.role`)"
                      v-model="selectedData.role"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      :label="$t(`profil.${this.lang}.branch`)"
                      v-model="selectedData.branch"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      :label="$t(`profil.${this.lang}.team_size`)"
                      v-model="selectedData.teamSize"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="menuStart"
                    :close-on-content-click="false"
                    transition="scale-transition">
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        :modelValue="startFormatted"
                        :label="$t(`profil.${lang}.start`) + '*'"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="props"
                        :rules="requiredRules"/>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      hide-header
                      @update:modelValue="menuStart = false"
                      :max="today"/>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="menuEnd"
                    :close-on-content-click="false"
                    transition="scale-transition">
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        v-model="endFormatted"
                        :label="$t(`profil.${lang}.end`)"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="props"/>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      hide-header
                      @update:modelValue="menuEnd = false"
                      :min="selectedData.start"
                      :disabled="!startDate"/>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                      width="100%"
                      outlined
                      :label="$t(`profil.${this.lang}.job_description`) + '*'"
                      v-model="selectedData.description"
                      required
                      :rules="requiredRules"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                      width="100%"
                      outlined
                      :label="$t(`profil.${this.lang}.task_description`) + '*'"
                      v-model="selectedData.jobs"
                      required
                      :rules="requiredRules"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      :label="$t(`profil.${this.lang}.expert_must_skill`) + '*'"
                      v-model="selectedData.skills"
                      :items="getUserSkills()"
                      item-title="name"
                      return-object
                      chips
                      multiple
                      clearable
                      :rules="requiredSkillsRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>{{'*' + $t(`profil.${this.lang}.required`)}}</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"

            @click="closeDialog()"
          >
            {{$t(`profil.${this.lang}.cancel`)}}
          </v-btn>
          <v-btn
            :disabled="!firstValid"
            color="primary"

            @click="saveData()"
          >
            {{$t(`profil.${this.lang}.save`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {today} from "../../utils/date.utils";

export default {
    props: {
      dialog: Boolean,
      selectedData:Object
    },
    data () {
      return {
        menuStart: false,
        menuEnd: false,
        myDialog: false,
        firstValid: false,
        requiredRules: [
          v => !!v || "Field is required"
        ],
        requiredSkillsRules: [ v => (v && v.length > 0) || "Field is required" ],
      }
    },
    computed: {
      ...mapState({
        basicData: state => state.users.myUser,
        lang: state => state.general.profilLang,
        skills: state => state.users.classifiedSkills,
      }),
      today,
      startDate: {
        get: function () {
          return new Date(this.selectedData.start);
        },
        set: function (val) {
          this.selectedData.start = new Date(val).toISOString();
        }
      },
      endDate: {
        get: function () {
          return new Date(this.selectedData.end);
        },
        set: function (val) {
          this.selectedData.end = new Date(val).toISOString();
        }
      },
      startFormatted () {
        return this.formatDate(this.selectedData.start);
      },
      endFormatted () {
        return this.formatDate(this.selectedData.end);
      },
    },
    watch: {
      'selectedData.skills'(newVal) {
        this.firstValid = !(!newVal || newVal.length === 0);
      }
    },
    methods: {
      ...mapActions('users', ['saveProjects']),
      getUserSkills() {
        let skillNames = [];
        this.skills.forEach(skill => {
          if (skill.offsprings && skill.offsprings.length > 0) {
            skill.offsprings.forEach(offspring => {
              if (offspring.userSkills && offspring.userSkills.length > 0) {
                offspring.userSkills.forEach(userSkill => {
                  console.log("last level", userSkill);
                  skillNames.push(userSkill);
                });
              }
            });
          } else if (skill.userSkills && skill.userSkills.length > 0) {
            skill.userSkills.forEach(userSkill => {
              skillNames.push(userSkill);
            })
          }
        });
        return skillNames;
      },
      closeDialog() {
        this.myDialog = this.dialog;
        this.$emit("update-expandProjects", !this.dialog);
      },
      saveData() {
        if (this.selectedData.skills.length > 0) {
          this.selectedData.skills = this.selectedData.skills.map(skill => {
          return {
            id: skill.skillId || skill.id,
            name: skill.name,
          };
        });
        this.selectedData.user = this.basicData;
        this.selectedData.user.id = this.basicData.userID;
        if (!Array.isArray(this.selectedData.jobs)) {
          this.selectedData.jobs = this.selectedData.jobs.split(/[\n,]+/).map(item => item.trim()).filter(item => item !== '');
        }
        console.info(this.selectedData.jobs);

        this.saveProjects({newProject : this.selectedData});
        this.myDialog = this.dialog;
        this.$emit("update-expandProjects", !this.dialog);
        } else {
          this.$refs.form.validate();
        }
      },
      formatDate(val) {
        if (!val) return null
        const date = new Date(val);
        const formatter = new Intl.DateTimeFormat(this.$t('i18n_date_time_format'), { day: '2-digit', month: '2-digit', year: 'numeric' });
        const formattedDate = formatter.format(date);
        return formattedDate;
      }
    }
  }
</script>

<style>
  .date-row {
    margin-left: 12px;
    margin-right: 12px;
  }
</style>
