<template>
  <div class="text-center">
    <v-dialog
      :model-value="dialog"
      persistent 
      width="800">
      <v-card>
        <v-card-title
          primary-title
        >
          {{$t(`profil.${this.lang}.languages`)}}
        </v-card-title>

        <v-divider></v-divider>

        <v-divider></v-divider>

        <v-card-text>
          <v-container
            fluid>
            <v-autocomplete
              v-model="selectedLanguages"
              :items="languages"
              item-title="name"
              :label="$t(`profil.${this.lang}.my_languages`)"
              chips
              multiple
            >
              <template v-slot:item="data">
                <v-list-item>
                  <v-container>
                    <v-row class="d-flex align-center">
                      <v-list-item-action>
                        <v-checkbox 
                          :input-value="isSelected({
                            data: data.item,
                            selected: selectedLanguages, 
                            userId: basicData.userID
                          })"
                          @change="selectLanguage(data.item)">
                        </v-checkbox>
                      </v-list-item-action>
                      <v-list-item-title style="transform: translateY(-9px);" >{{data.item.raw.name}}</v-list-item-title>
                    </v-row>
                  </v-container>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-container>

          <v-container  v-for="item in selectedLanguages"
            :key="item.name"
            fluid >
            <v-toolbar
              flat>
              <v-btn
                color="red" 
                @click="deleteSelected(item)"
                icon>
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
              <v-toolbar-title>{{item.name}}</v-toolbar-title>
              <v-spacer/>
                  <select
                    v-model="item.level"
                    label="Level" 
                  >
                  <option v-for="level in languageLevels" :value="level.key" >
                    {{ level.value }}
                  </option>             
              </select>
            </v-toolbar>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            
            @click="closeDialog()">
            {{$t(`profil.${this.lang}.cancel`)}}
          </v-btn>
          <v-btn
            color="primary"
            @click="saveLanguages()">
            {{$t(`profil.${this.lang}.save`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
   import {mapState, mapActions} from 'vuex'

   export default {
    props: {
      dialog: Boolean,
      myLanguages: Array
    },
    data () {
      return {
        myDialog: false,
        selectedRatings: [],
        selectedLanguages: [],
        languageLevels: [],
      }
    },
    computed: {
      ...mapState({
        basicData: state => state.users.myUser,
        languages: state => state.users.languages,
        lang: state => state.general.profilLang,
      }),
    },
    created () {
      this.getLanguages(this.$i18n.locale);
    },
    watch: {
      languages(val) {
        if (val.length) {
          this.languageLevels = Object.entries(this.languages[0].rating.allowedValues).map(([key, value]) => ({key: key, value: value}));
          for (let skill in this.myLanguages) {
            for (let item in this.languages) {
              if (this.languages[item].id === this.myLanguages[skill].id) {
                this.languages[item].level = this.myLanguages[skill].level;
                this.languages[item].userId = this.myLanguages[skill].userId;
                this.selectedLanguages.push(this.languages[item]);
                break;
              } 
            }
          }
        }
      }
    },
    // filters: {
    //   selected(data) {
    //     if(data.data.level > 0 && data.selected.indexOf(data.data) < 0) {
    //       data.data.userId = data.userId;
    //       data.selected.push(data.data);
    //     }
    //     return data.selected.indexOf(data.data) > -1; 
    //   }
    // },
    methods: {
      ...mapActions('users', ['saveMyLanguages', 'deleteMyLanguages', 'getLanguages']),
      closeDialog() {
        this.myDialog = this.dialog;
        this.$emit("update-expandLanguages", !this.dialog);
      },
      isSelected(data) {
        if (data.data.level > 0 && data.selected.indexOf(data.data) < 0) {
        data.data.userId = data.userId;
        data.selected.push(data.data);
      }
      return data.selected.indexOf(data.data) > -1;
      },
      selectLanguage(item) {
        const formattedItem = this.formatLanguage(item);
        let index = this.selectedLanguages.indexOf(item);
        if(index < 0) {
          formattedItem.level = 1;
          formattedItem.userId = this.basicData.userID;
          this.selectedLanguages.push(formattedItem);
        } else {
          item.level = 0;
          item.userId = null;
          this.selectedLanguages.splice(index, 1)
        }
      },
      saveLanguages() {
        if(this.selectedLanguages.length > 0) {
          this.saveMyLanguages({newMyLanguages : this.selectedLanguages});
        } else {
          this.deleteMyLanguages({
            userId: this.basicData.userID
          })
        }
        this.closeDialog();
      },
      deleteSelected(item) {
        let index = this.selectedLanguages.indexOf(item);
        if(index > -1) {
          item.level = 0;
          item.userId = null;
          this.selectedLanguages.splice(index, 1)
        }
      },
      formatLanguage(item) {
        return {
          id: item.raw.id,
          level: item.raw.level,
          code: this.lang,
          userId: item.userId,
          name: item.raw.name,
          relevance: item.raw.relevance,
          rating: item.raw.rating
        }
      }
    }
  }
</script>
