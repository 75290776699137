<template>
  <div class="text-center">
    <v-dialog
      :model-value="dialog"
      persistent
      width="500">
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between">
            <span>{{ $t(`i18n_download_profile`) + ': ' + selectedData.userDto?.firstname + ' ' + selectedData.userDto?.lastname}}</span>
            <v-spacer/>
            <v-btn icon="mdi-close-circle" @click="closeDialog" density="compact" variant="text"/>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="selectedData">
          <v-container>
            <v-form v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-select
                    v-if="templates"
                    v-model="requestDto.configId"
                    item-title="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'required']"
                    :label="$t(`i18n_template`)"
                    :items="templates"/>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-select
                    v-model="requestDto.outputFormat"
                    item-title="val"
                    item-value="key"
                    :rules="[(v) => !!v || 'required']"
                    :label="$t(`i18n_format`)"
                    :items="types"/>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-select
                    v-if="contacts"
                    v-model="requestDto.contactId"
                    item-title="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'required']"
                    :label="$t(`i18n_contact`)"
                    :items="contacts"/>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!valid" color="primary" @click="download()">{{ $t(`i18n_download`) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "vuex";
import { printConfigService, contactService } from '../../services';

export default {
  data: () => ({
    valid: true,
    templates: [],
    types: [{key: 'NATIVE', val: 'DOCX'}, {key: 'PDF', val:'PDF'}],//TODO dependin on template format
    contacts: [],
  }),
  props: {
    dialog: Boolean,
    selectedData: Object,
    skillsWanted: Array,
  },
  mounted() {
    this.getTemplates();
    this.getContacts();
  },
  methods: {
    async getTemplates() {
      this.templates = await printConfigService.list();
    },
    async getContacts() {
      this.contacts = await contactService.list();
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const requestDto = reactive({});
    const closeDialog = () => {
      requestDto.configId = null;
      requestDto.outputFormat = null;
      requestDto.contactId = null;
      emit("closeDialog");
    };
    const download = () => {
      requestDto.userId = props.selectedData.userDto.userID;
      requestDto.skillsWanted = props.skillsWanted;
      store.dispatch("publication/download", requestDto);
      closeDialog();
    };

    return {
        requestDto,
        closeDialog,
        download,
    };
  }
}
</script>
