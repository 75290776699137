<template>
  <div class="text-center">
    <v-dialog
      :model-value="dialog"
      persistent 
      width="800"
    >
      <v-card>
        <v-card-title primary-title>
          {{$t(`profil.${this.lang}.certificate`)}}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-form v-model="firstValid">
              <v-row>
                <v-col cols="12">
                  <v-select
                    :label="$t(`i18n_qualification_type`) + '*'"
                    v-model="selectedData.type"
                    :rules="requiredRules"
                    :items="certTypes"/>
                </v-col>
                <v-col cols="12" v-if="'CERTIFICATE' === selectedData.type">
                  <v-text-field
                    :label="$t(`i18n_cert_url`) + '*'"
                    v-model="selectedData.url"
                    :rules="urlRule"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field 
                    :label="$t(`profil.${this.lang}.certificate_title`) + '*'"
                    v-model="selectedData.name"
                    required
                    :rules="requiredRules">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field 
                    :label="$t(`profil.${this.lang}.issued_by`) + '*'"
                    v-model="selectedData.issuedBy"
                    :rules="requiredRules">
                  </v-text-field>
                </v-col>
                <v-row class="ml-1">
                  <v-col cols="12">
                    <v-menu
                      v-model="menuDate"
                      :close-on-content-click="false"
                      transition="scale-transition">
                      <template v-slot:activator="{ props }">
                        <v-text-field
                          v-model="dateFormatted"
                          :label="$t(`profil.${lang}.valid_from`) + '*'"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="props"
                          :rules="requiredRules"/>
                      </template>
                      <v-date-picker
                        v-model="dateIssued"
                        hide-header
                        @update:modelValue="menuDate = false"
                        :max="today"/>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-row>
            </v-form> 
          </v-container>
          <small>{{'*' + $t(`profil.${this.lang}.required`)}}</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="closeDialog()"
          >
            {{$t(`profil.${this.lang}.cancel`)}}
          </v-btn>
          <v-btn
            :disabled="!firstValid"
            color="primary"
            @click="saveData()"
          >
            {{$t(`profil.${this.lang}.save`)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex';
  import { today } from "../../utils/date.utils";

  export default {
    props: {
      dialog: Boolean,
      selectedData:Object
    },
    data: function () {
      return {
        menuDate:false,
        firstValid: true,
        requiredRules: [v => !!v || "Field is required"],
        urlRule: [v => !!v && /^https?:\/\/(?:[a-z0-9][a-z0-9-]*[a-z0-9]\.)+[a-z]{2,}(?:\/.*)*$/i.test(v) || "must be a URL"],
        certTypes: [{value: 'CERTIFICATE', title: this.$i18n.t('i18n_CERTIFICATE')}, {value: 'TRAINING', title: this.$i18n.t('i18n_TRAINING')}],
      }
    },
    computed: {
      ...mapState({
        basicData: state => state.users.myUser,
        lang: state => state.general.profilLang,
      }),
      today,
      dateIssued: {
        get: function () {
          return new Date(this.selectedData.dateIssued);
        },
        set: function (val) {
          this.selectedData.dateIssued = new Date(val).toISOString();
        }
      },
      dateFormatted () {
        return this.formatDate(this.selectedData.dateIssued);
      },
    },
    methods: {
      ...mapActions('users', ['saveCertificate']),
      closeDialog() {
        this.$emit("update-expandCertificates", !this.dialog);
      },
      saveData() {
        this.selectedData.userId = this.basicData.userID;
        this.saveCertificate({newCertificate : this.selectedData});
        this.$emit("update-expandCertificates", !this.dialog);
      },
      formatDate(val) {
        if (!val) return null
        const date = new Date(val);
        const formatter = new Intl.DateTimeFormat(this.$t('i18n_date_time_format'), { day: '2-digit', month: '2-digit', year: 'numeric' });
        const formattedDate = formatter.format(date);
        return formattedDate;
      }
    }
  }
</script>
