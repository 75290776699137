<template>
    <div class="d-flex align-center">
        <EditPrintConfig :selectedData="selectedData" :dialog="expandPrintConfig" @closeDialog="closeDialog"/>
    </div>
    <v-card class="view">
        <v-card-title class="d-flex align-center justify-space-between">
            <v-spacer></v-spacer>
            <v-btn 
                icon
                elevation="0"
                @click="editConfig()">
                <v-icon>{{ "mdi-plus-circle" }}</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text height="100%">
            <v-row>
                <v-col v-for="config in configs" v-bind:key="config.id" cols="4">
                    <PrintConfig
                        :edit="expandPrintConfig" 
                        :config="config"
                        @edit="editConfig($event)"
                        @delete="deleteConfig($event)">
                    </PrintConfig>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import PrintConfig from '../../components/customItems/PrintConfig.vue';
import EditPrintConfig from '../customDialogs/EditPrintConfig.vue';
import { printConfigService } from '../../services';
import { mapState, mapActions } from 'vuex';

export default {
    data: () => ({
        selectedData: {},
        expandPrintConfig: false
    }),
    components: {
        PrintConfig,
        EditPrintConfig
    },
    computed: {
        ...mapState({
            configs: state => state.printConfig.configs,
            loading: state => state.printConfig.all.loading,
        })
    },
    created() {
        this.list();
    },
    methods: {
        ...mapActions('printConfig', ['list', 'delete']),
        async editConfig(event) {
            this.selectedData = event ? await printConfigService.get(event) : {'parameter':{}};
            this.expandPrintConfig = true;
        },
        deleteConfig(event) {
            this.delete(event);
        },
        closeDialog() {
            this.expandPrintConfig = false;
        }
    }
};
</script>

<style scoped>
.view {
    width:100%;
    height:90%;
}
</style>
