<template>
  <div class="text-center">
    <v-dialog
      :model-value="dialog"
      persistent
      width="800">
      <v-card>
        <v-card-title primary-title>
          {{ selectedData.name ?? $t(`i18n_new_print_template`) }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="selectedData">
          <v-container>
            <v-form v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-file-input v-if="!selectedData.template"
                    :label="$t(`i18n_template_file`) + '*'"
                    v-model="selectedData.templateFile"  
                    accept=".docx"
                    @change="uploadTemplate"/>
                  <span v-else>
                    <v-btn @click="removeTemplate" icon="mdi-file-remove-outline" density="compact" variant="text"/>
                    <v-btn @click="downloadTemplate" icon="mdi-download" density="compact" variant="text"/>
                    {{ selectedData.fileName }}
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t(`i18n_name`) + '*'"
                    v-model="selectedData.name"
                    :rules="requiredRules"
                    required
                    maxlength="255"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t(`i18n_description`)"
                    v-model="selectedData.description"
                    maxlength="255"/>
                </v-col>
                <v-col cols="12">
                  <v-radio-group
                    :label="$t(`i18n_conf_language`) + '*'"
                    v-model="selectedData.language"
                    :rules="requiredRules">
                    <v-radio :label="$t(`i18n_lang_de`)" value="de"></v-radio>
                    <v-radio :label="$t(`i18n_lang_en`)" value="en"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row>
                <v-col><p class="font-weight-black">{{ $t(`i18n_parameter`) }}</p></v-col>
              </v-row>
              <v-row v-for="key in Object.keys(selectedData.parameter)" dense>
                <v-col cols="5">{{ key }}</v-col>
                <v-col cols="6"><v-text-field v-model="selectedData.parameter[key]"/></v-col>
                <v-col cols="1"><v-btn icon="mdi-minus-circle" @click="delete selectedData.parameter[key]"/></v-col>
              </v-row>
              <v-row dense>
                <v-col cols="5"><v-text-field v-model="newKey" @keyup="normalize"/></v-col>
                <v-col cols="6"><v-text-field v-model="newVal"/></v-col>
                <v-col cols="1"><v-btn icon="mdi-plus-circle" @click="addParameter"/></v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>{{ '*' + $t(`i18n_field_required`) }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDialog()">{{ $t(`i18n_cancel`) }}</v-btn>
          <v-btn :disabled="invalid()" color="primary" @click="saveData()">{{ $t(`i18n_save`) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  data: () => ({
    valid: true,
    newKey: null,
    newVal: null
  }),
  props: {
    dialog: Boolean,
    selectedData: Object
  },
  methods: {
    addParameter() {
      if (!this.newKey) return;
      this.selectedData.parameter[this.newKey] = this.newVal;
      this.newKey = null;
      this.newVal = null;
    },
    normalize() {
      this.newKey = this.newKey.replace(/[^a-zA-Z0-9]/g, '_').toUpperCase();
    },
    invalid() {
      return !this.valid || this.selectedData.template == null || !this.selectedData.fileName.endsWith('.docx');
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const requiredRules = [
      v => !!v || "Field is required"
    ];
    const uploadTemplate = () => {
      const template = props.selectedData.templateFile;
      if (template && template instanceof File) {
        const reader = new FileReader();
        reader.readAsDataURL(template);
        reader.onload = e => {
          if (e.target.result) {
            props.selectedData.template = e.target.result;
            props.selectedData.fileName = template.name;
            const ext = template.name.split('.').pop().toLowerCase();
            switch (ext) {
              case 'docx':
                props.selectedData.processor = 'wordProcessor';
                break;
              default:
                removeTemplate();
            }
          }
          else {
            console.error("Fehler beim Laden der Templatedatei");
            removeTemplate();
          }
        };
        reader.onerror = () => {
          console.error("Fehler beim Lesen der Datei");
          removeTemplate();
        };
      }
      else {
        console.error("Ungültige Templatedatei");
        removeTemplate();
      }
    };
    const downloadTemplate = () => {
      const data = props.selectedData.template.split(',');
      const type = /^data:(.*);/.exec(data[0])[1];
      const content = atob(data[1]);
      // convert String to Bytearray
      const ab = new ArrayBuffer(content.length);
      const ia = new Uint8Array(ab);
      for (var i = 0; i < content.length; i++) ia[i] = content.charCodeAt(i);
      // create Blob
      const blob = new Blob([ab], {type: type});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style = "display: none";
      a.href = url;
      a.download = props.selectedData.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(a.href);
      a.remove();
    };
    const removeTemplate = () => {
      props.selectedData.template = null;
      props.selectedData.processor = null;
      props.selectedData.fileName = null;
    };
    const closeDialog = () => {
      emit("closeDialog");
    };
    const saveData = () => {
      store.dispatch("printConfig/save", props.selectedData);
      closeDialog();
    };

    return {
      requiredRules,
      uploadTemplate,
      downloadTemplate,
      removeTemplate,
      closeDialog,
      saveData,
    };
  }
};
</script>
