export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
               // auto logout if 401 response returned from api
               handleLogout();
               location.reload();
               //this.$router.push('/login');
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export function handleDownload(response) {
    return response.blob().then(blob => {
        if (!response.ok) {
            const error = response.statusText;
            return Promise.reject(error);
        }
        const type = response.headers.get("content-type");
        const name = response.headers.get('content-disposition')?.match(/filename="(.*)"/)[1];
        const file = new File([blob], name, {type: type});
        const url = window.URL.createObjectURL(file);
        const a = document.createElement('a');
        a.style = "display: none";
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
        a.remove();
    });
}

export function handleLogout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('preferredLanguage');
}
