<template>
  <v-data-table
    :headers="tableHeaders"
    :items="contacts"
    :search="search"
    item-key="id"
    :loading="loading"
    style="margin-top: 10px">
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{$t('i18n_contacts')}}</v-toolbar-title>
        <v-spacer/>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('i18n_search')"
          single-line
          hide-details/>
        <v-spacer/>
        <v-btn icon="mdi-plus-circle" elevation="0" @click="edit({})"/>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <div class="align-center justify-space-between">
        <v-icon small class="mr-2" @click="edit(item)" icon="mdi-pen"/>
        <v-icon small class="mr-2" @click="deleteWarn(item)" icon="mdi-delete"/>
      </div>
    </template>
  </v-data-table>
  <DeleteWarning :dialog="deleteDialog" @fire-no="deleteClose" @fire-yes="deleteSend"/>
  <EditContact :contact="selectedData" :dialog="editDialog" @save="saveContact" @close="closeDialog"/>
</template>

<script>
import EditContact from '../customDialogs/EditContact.vue';
import DeleteWarning from '../customDialogs/DeleteWarning.vue';
import { mapState, mapActions } from 'vuex';

export default {
  data: () => ({
    selectedData: {},
    editDialog: false,
    deleteDialog: false,
  }),
  components: {
    EditContact,
    DeleteWarning
  },
  computed: {
    ...mapState({
      contacts: state => state.contact.contacts,
      loading: state => state.contact.all.loading,
    }),
    tableHeaders() {
      var result = [
        { title: this.$t('i18n_name'), value: 'name', sortable: true },
        { title: this.$t('i18n_title'), value: 'title', sortable: true },
        { title: this.$t('i18n_email'), value: 'mail', sortable: true },
        { title: this.$t('i18n_phone'), value: 'phone', sortable: true },
        { title: this.$t('i18n_actions'), value: 'action', sortable: false }
      ]
      return result;
    }
  },
  created() {
    this.list();
  },
  methods: {
    ...mapActions('contact', ['save', 'list', 'delete']),
    edit(event) {
      this.selectedData = event;
      this.editDialog = true;
    },
    deleteWarn(event) {
      this.selectedData = event;
      this.deleteDialog = true;
    },
    deleteSend() {
      this.delete(this.selectedData);
      this.deleteClose();
    },
    deleteClose() {
      this.deleteDialog = false;
      this.selectedData = {};
    },
    saveContact() {
      this.save(this.selectedData);
      this.closeDialog();
    },
    closeDialog() {
      this.editDialog = false;
      this.selectedData = {};
    }
  }
};
</script>

<style scoped>
.view {
    width:100%;
    height:90%;
}
</style>
