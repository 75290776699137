<template>
    <div>
      <v-card class="view" v-if="!selectedTemplate">
        <v-card-title class="d-flex align-center justify-space-between">
          <v-spacer></v-spacer>
          <v-btn 
            icon
            elevation="0"
            @click="create()">
              <v-icon>{{ "mdi-plus-circle"}}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text height="100%">
          <v-row>
            <v-col v-for="template in templates" v-bind:key="template.id" cols="4">
              <Templates 
              :preview="preview" 
              :template="template"
               @selected="selection($event)"
               @previewed="previewTemplate($event)"
               @deleted="deleteTemplate($event)"
               @depreview="updatePreview($event)" 
               >
  
               </Templates>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <template-editor v-else 
      :profile="me" 
      :skills="skills" 
      :template="selectedTemplate" 
      :sections="sections" 
      @unselect="unselect()" 
      @save="saveTemplate($event)"
      :preview="preview"
      @update:preview="preview = $event"
      :classifiedSkills="classifiedSkills"
      ></template-editor>
    </div>
  </template>
  
  
  <script>
  import Templates from '../../components/customItems/Templates.vue';
  import TemplateEditor from '../../components/customItems/TemplateEditor.vue'
  import { mapState, mapActions } from 'vuex';
  
   export default {
      data: () => ({
        show: false,
        dialog: false,
        selectedTemplate: null,
        preview: false,
        source: ""
      }),
      components: {
        Templates,
        TemplateEditor
      },
      computed: {
        ...mapState({
          templates: state => state.template.all.items,
          template: state => state.template.template,
          loading: state => state.all.loading,
          sections: state => state.template.sections,
          me: state => state.users.myProfile,
          skills: state => state.users.simpleClassification,
          classifiedSkills: state => state.users.classifiedSkills,
        })
      },
       created() {
        this.getAll();
        this.getTemplates();
        if (this.me) {
          this.getUserById({userId: this.me.user.userID, language: this.$i18n.locale });
        }
      },
      methods: {
        ...mapActions('template', ['getAll','save','getTemplates','delete']),
        ...mapActions('users', ['getUserById']),
        selection(event){
          this.selectedTemplate = event;
        },
        previewTemplate(event){
          this.preview = true;
          this.selectedTemplate = event;
        },
        deleteTemplate(event){
          //this.delete(event);
          this.delete(event).then(() => {
            this.getAll();
            this.selectedTemplate = null;
          });
        },
        unselect(){
          this.selectedTemplate = null;
          this.preview = false;
        },
        create(){
          this.selectedTemplate = {};
        },
        saveTemplate(template){
          this.save(template);
          this.getAll();
          this.selectedTemplate = null;
        },
        updatePreview(newVal) {
          this.preview = newVal;
    }
      }
      
    }
  </script>
  <style scoped>
  .view {
      width:100%;
      height:90%;
    }
  </style>