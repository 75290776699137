import {contactService} from '../services';

const state = {
    all: {},
    contacts: []
};

const actions = {
    list({commit}) {
        commit('setLoading', true);
        contactService.list().then(
            contacts => {
                commit('listSuccess', contacts);
                commit('setLoading', false);
            },
            error => {
                commit('requestFailure', error);
                commit('setLoading', false);
            }
        );
    },
    delete({dispatch, commit}, data) {
        commit('setLoading', true);
        if (data.id) {
            contactService.del(data).then(
                () => {
                    dispatch('list');
                },
                error => {
                    commit('requestFailure', error);
                    commit('setLoading', false);
                }
            );
        }
    },
    save({dispatch, commit}, data) {
        commit('setLoading', true);
        contactService.save(data).then(
            () => {
                dispatch('list');
            },
            error => {
                commit('requestFailure', error);
                commit('setLoading', false);
            }
        );
    }
}

const mutations = {
    setLoading(state, payload) {
        state.all.loading = payload ;
    },
    listSuccess(state, contacts) {
        state.contacts = contacts;
    },
    requestFailure(state, error) {
        state.all = { error };
    }
}

export const contact = {
    namespaced: true,
    state,
    actions,
    mutations
};
