import {printConfigService} from '../services';

const state = {
    all: {},
    configs: []
};

const actions = {
    list({commit}) {
        commit('setLoading', true);
        printConfigService.list().then(
            configs => {
                commit('listSuccess', configs);
                commit('setLoading', false);
            },
            error => {
                commit('requestFailure', error);
                commit('setLoading', false);
            }
        );
    },
    delete({dispatch, commit}, data) {
        commit('setLoading', true);
        if (data.id) {
            printConfigService.del(data).then(
                () => {
                    dispatch('list');
                },
                error => {
                    commit('requestFailure', error);
                    commit('setLoading', false);
                }
            );
        }
    },
    save({dispatch, commit}, data) {
        commit('setLoading', true);
        printConfigService.save(data).then(
            () => {
                dispatch('list');
            },
            error => {
                commit('requestFailure', error);
                commit('setLoading', false);
            }
        );
    }
}

const mutations = {
    setLoading(state, payload) {
        state.all.loading = payload ;
    },
    listSuccess(state, configs) {
        state.configs = configs;
    },
    requestFailure(state, error) {
        state.all = { error };
    }
}

export const printConfig = {
    namespaced: true,
    state,
    actions,
    mutations
};
