import { userService } from '../services';

const state = {
    all: {},
    allUsers: [],
    myUser: {},
    studies: [],
    experience: [],
    languages: [],
    projects: [],
    myLanguages: null,
    classifiedSkills: null,
    certificates: [],
    skillSelection: {},
    myProfile: {}
};

const actions = {
    getAll({ commit }) {
        commit('setLoading', true);

        userService.getAll()
            .then(
                users => {
                    commit('getAllSuccess', users);
                    commit('setLoading', false);
                },
                error => {
                    commit('getAllFailure', error);
                    commit('setLoading', false);
                }
            );
    },
    delete({ commit }, id) {
        commit('deleteRequest', id);

        userService.delete(id)
            .then(
                error => commit('deleteFailure', { id, error: error.toString() })
            );
    },
    getUserById({ commit }, data) {
        commit('setLoading', true);
        userService.getById(data)
            .then(
                users => {
                    commit('getByIdSuccess', users);
                    commit('setLoading', false);
                },
                error => {
                    commit('getByIdFailure', error);
                    commit('setLoading', false);
                }
            );
    },
    getAvailableSkills({ commit }, data) {
        commit('initSkillSelection');
        userService.getAvailableSkills(data).then(
            skillSelection => commit('getAvailableSkillsSuccess', skillSelection),
            error => commit('getByIdFailure', error)
        );
    },
    getLanguages({ commit }, data) {
        userService.getLanguages(data).then(
            data => commit('getLanguagesSuccess', data),
            error => commit('getAllFailure', error)
        );
    },
    saveBasicData({ dispatch, commit }, basicData) {
        userService.saveBasicData(basicData)
            .then(
                () => {
                    commit('saveBasicDataSuccess', basicData);
                    dispatch('alert/success', 'Succesfully saved Basic Data!', { root: true });
                },
                error => commit('getAllFailure', error)
            );
    },
    saveEducation({ dispatch, commit }, education) {
        userService.saveEducation(education)
            .then(
                studies => {
                    commit('saveEducationSuccess', studies);
                    setTimeout(() => {
                        dispatch('alert/success', 'Succesfully saved education!', { root: true });
                    });
                },
                error => {
                    commit('getAllFailure', error);
                    dispatch('alert/error', 'Saving education failed!!', { root: true });
                }
            );
    },
    deleteEducation({ dispatch, commit }, id) {
        userService.deleteEducation(id)
            .then(
                () => {
                    commit('deleteEducationSuccess', id);
                    setTimeout(() => {
                        dispatch('alert/success', 'Succesfully deleted education!', { root: true });
                    })
                },
                error => {
                    commit('getAllFailure', error);
                    dispatch('alert/error', 'Deleting education failed!!', { root: true });
                }
            );
    },
    saveExperience({ dispatch, commit }, experience) {
        userService.saveExperience(experience)
            .then(
                data => {
                    commit('saveExperienceSuccess', data);
                    setTimeout(() => {
                        dispatch('alert/success', 'Succesfully saved experience!', { root: true });
                    })
                },
                error => {
                    commit('getAllFailure', error);
                    dispatch('alert/success', 'Saving experience failed!', { root: true });
                }
            );
    },
    saveProjects({ dispatch, commit }, project) {
        userService.saveProjects(project)
            .then(
                data => {
                    commit('saveProjectSuccess', data);
                    setTimeout(() => {
                        dispatch('alert/success', 'Succesfully saved Project!', { root: true });
                    })
                },
                error => {
                    commit('getAllFailure', error);
                    dispatch('alert/success', 'Saving Project failed!', { root: true });
                }
            );
    },
    deleteExperience({ dispatch, commit }, id) {
        userService.deleteExperience(id)
            .then(
                () => {
                    commit('deleteExperienceSuccess', id);
                    setTimeout(() => {
                        dispatch('alert/success', 'Succesfully deleted experience!', { root: true });
                    })
                },
                error => {
                    commit('getAllFailure', error);
                    dispatch('alert/success', 'Deleting experience failed!', { root: true });
                }
            );
    },
    deleteProject({ dispatch, commit }, id) {
        userService.deleteProject(id)
            .then(
                () => {
                    commit('deleteProjectSuccess', id);
                    setTimeout(() => {
                        dispatch('alert/success', 'Succesfully deleted project!', { root: true });
                    })
                },
                error => {
                    commit('getAllFailure', error);
                    dispatch('alert/success', 'Deleting project failed!', { root: true });
                }
            );
    },
    saveCertificate({ dispatch, commit }, certificate) {
        userService.saveCertificate(certificate)
            .then(
                data => {
                    commit('saveCertificateSuccess', data);
                    setTimeout(() => {
                        dispatch('alert/success', 'Succesfully saved certificate!', { root: true });
                    })
                },
                error => {
                    commit('getAllFailure', error);
                    dispatch('alert/error', 'Saving certificate failed!', { root: true });
                })
    },
    deleteCertificate({ dispatch, commit }, id) {
        userService.deleteCertificate(id)
            .then(
                () => {
                    commit('deleteCertificateSuccess', id);
                    setTimeout(() => {
                        dispatch('alert/success', 'Succesfully deleted certificate!', { root: true });
                    })
                },
                error => {
                    commit('getAllFailure', error);
                    dispatch('alert/error', 'Deleting certificate failed!', { root: true });
                }
            );
    },

    saveMySkills({ dispatch, commit }, mySkills) {
        userService.saveMySkills(mySkills)
            .then(
                data => {
                    commit('saveMySkillsSuccess', data);
                    setTimeout(() => {
                        dispatch('alert/success', 'Succesfully saved skills!', { root: true });
                    })
                },
                error => {
                    commit('getAllFailure', error);
                    dispatch('alert/error', 'Saving skills failed!', { root: true });

                }
            );
    },

    deleteMySkills({ dispatch, commit }, myClassification) {
        userService.deleteMySkills(myClassification)
            .then(
                data => {
                    commit('saveMySkillsSuccess', data);
                    setTimeout(() => {
                        dispatch('alert/success', 'Successfully deleted skills!', { root: true });
                    })
                },
                error => {
                    commit('getAllFailure', error);
                    dispatch('alert/error', 'Deleting skills failed!', { root: true });
                }
            );
    },

    saveMyLanguages({ dispatch, commit }, myLanguages) {
        userService.saveMyLanguages(myLanguages)
            .then(
                data => {
                    commit('saveMyLanguagesSuccess', data);
                    setTimeout(() => {
                        dispatch('alert/success', 'Successfully saved languages!', { root: true });
                    })
                },
                error => {
                    commit('getAllFailure', error);
                    dispatch('alert/error', 'Saving languages failed!', { root: true });
                }
            );
    },

    deleteMyLanguages({ dispatch, commit }, myLanguages) {
        userService.deleteMyLanguages(myLanguages)
            .then(
                data => {
                    commit('saveMyLanguagesSuccess', data);
                    setTimeout(() => {
                        dispatch('alert/success', 'Successfully deleted languages!', { root: true });
                    })
                },
                error => {
                    commit('getAllFailure', error);
                    dispatch('alert/error', 'Deleting languages failed!', { root: true });
                }

            );
    },
};

const mutations = {
    setLoading(state, payload) {
        state.all = { loading: payload };
    },
    getAllSuccess(state, users) {
        state.allUsers = users;
    },
    getAllFailure(state, error) {
        state.all = { error };
    },
    getByIdSuccess(state, users) {
        state.myUser = users.user;
        state.studies = users.studies;
        state.experience = users.experience;
        state.myLanguages = users.myLanguages;
        state.classifiedSkills = users.classifiedSkills;
        state.certificates = users.certificates;
        state.projects = users.projects;
        state.myProfile = users;
    },
    getByIdFailure(state, error) {
        state.all = { error };
    },
    initSkillSelection(state) {
        state.skillSelection = {}
    },
    getAvailableSkillsSuccess(state, skillSelection) {
        state.skillSelection = skillSelection;
    },
    getLanguagesSuccess(state, data) {
        state.languages = data;
    },
    deleteRequest(state, id) {
        // add 'deleting:true' property to user being deleted
        state.all.items = state.all.items.map(user =>
            user.id === id
                ? { ...user, deleting: true }
                : user
        )
    },
    deleteSuccess(state, id) {
        // remove deleted user from state
        state.all.items = state.all.items.filter(user => user.id !== id)
    },
    deleteFailure(state, { id, error }) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
        state.all.items = state.items.map(user => {
            if (user.id === id) {
                // make copy of user without 'deleting:true' property
                const { ...userCopy } = user;
                // return copy of user with 'deleteError:[error]' property
                return { ...userCopy, deleteError: error };
            }

            return user;
        })
    },
    saveBasicDataSuccess(state, basicData) {
        state.myUser = basicData.newBasicData;
    },
    saveEducationSuccess(state, education) {
        state.studies = state.studies.filter(studie => studie.id !== education.id);
        state.studies.push(education);
    },
    deleteEducationSuccess(state, id) {
        // remove deleted user from state
        state.studies = state.studies.filter(studie => studie.id !== id.id);
    },
    saveExperienceSuccess(state, experience) {
        state.experience = state.experience.filter(p => p.id !== experience.id);
        state.experience.push(experience);
    },
    deleteExperienceSuccess(state, id) {
        // remove deleted user from state
        state.experience = state.experience.filter(experience => experience.id !== id.id);
    },
    saveProjectSuccess(state, project) {
        state.projects = state.projects.filter(p => p.id !== project.id);
        state.projects.push(project);
    },
    deleteProjectSuccess(state, id) {
        // remove deleted user from state
        state.projects = state.projects.filter(project => project.id !== id.id);
    },
    saveCertificateSuccess(state, certificate) {
        state.certificates = state.certificates.filter(p => p.id !== certificate.id);
        state.certificates.push(certificate);
    },
    deleteCertificateSuccess(state, id) {
        // remove deleted user from state
        state.certificates = state.certificates.filter(certificate => certificate.id !== id.id);
    },
    saveMySkillsSuccess(state, classifiedSkills) {
        state.classifiedSkills = classifiedSkills;
    },
    saveMyLanguagesSuccess(state, myLanguages) {
        state.myLanguages = myLanguages;
    }
};

export const users = {
    namespaced: true,
    state,
    actions,
    mutations
};
