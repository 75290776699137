<template>
  <div class="text-center">
    <v-dialog
      :model-value="dialog"
      persistent
      width="800"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-if="isEditable"
          v-bind="props"
          elevation="0"
          icon
          @click="closeDialog()">
          <v-icon color="indigo lighten-1">mdi-pen</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title primary-title>
          {{ $t(`profil.${lang}.basicdata`) }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text v-if="selectedData">
          <v-container>
            <v-form ref="firstForm" :modelValue="firstValid">
              <v-layout align="center" justify="center" fill-height>
                <div>
                  <img
                    v-if="selectedData.image"
                    :src="selectedData.image"
                    class="uploading-image"
                  />
                  <v-row v-else align="center" justify="center">
                    <v-icon size="200px">mdi-account-off</v-icon>
                  </v-row>
                  <v-file-input
                    :label="$t(`profil.${lang}.filename`)"
                    v-model="selectedData.imageFile"  
                    @change="uploadAvatar"
                  ></v-file-input>
                </div>
              </v-layout>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="$t(`profil.${lang}.firstname`) + '*'"
                    v-model="selectedData.firstname"
                    :rules="requiredRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :label="$t(`profil.${lang}.lastname`) + '*'"
                    v-model="selectedData.lastname"
                    :rules="requiredRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-row>
                    <v-checkbox
                      :label="$t(`profil.${lang}.external`)"
                      v-model="selectedData.external"
                      color="primary"
                      class="flex right"
                    ></v-checkbox>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t(`profil.${lang}.position`)"
                    v-model="selectedData.position"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t(`profil.${lang}.degree`)"
                    v-model="selectedData.degree"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t(`profil.${lang}.email`) + '*'"
                    v-model="selectedData.email"
                    :rules="requiredRules"
                    type="email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-menu
                    v-model="menu.value"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="birthday" 
                        :label="$t(`profil.${lang}.birthday`)"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="birthday"
                      @change="selectDate"
                      :max="today"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="selectedData.phone"
                    prepend-inner-icon="mdi-phone"
                    :label="$t(`profil.${lang}.telefon`)"
                  ></v-text-field>
                </v-col>
                <v-container>
                  <v-text-field
                    v-model="selectedData.address"
                    prepend-inner-icon="mdi-map-marker"
                    :label="$t(`profil.${lang}.adress`)"
                  ></v-text-field>
                  <v-textarea
                    v-model="selectedData.summary"
                    :label="$t(`profil.${lang}.summary`)"
                  ></v-textarea>
                </v-container>
              </v-row>
            </v-form>
          </v-container>
          <small>{{ '*' + $t(`profil.${lang}.required`) }}</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDialog()">
            {{ $t(`profil.${lang}.cancel`) }}
          </v-btn>
          <v-btn :disabled="!firstValid" color="primary" @click="saveData()">
            {{ $t(`profil.${lang}.save`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { today } from "../../utils/date.utils";

export default {
  props: {
    dialog: Boolean,
    selectedData: Object
  },
  setup(props, { emit }) {
    const store = useStore();
    const menu = ref(false);
    const firstValid = ref(true);
    const requiredRules = [
      v => !!v || "Field is required"
    ];

    const selectedData = computed(() => props.selectedData || {});

    const lang = computed(() => store.state.general.profilLang);
    const isEditable = computed(() => store.state.general.selectedPageEditable);
    const birthday = ref(props.selectedData?.birthday
      ? new Date(props.selectedData.birthday).toISOString().substr(0, 10)
      : "");

    const getImageTitle = computed(() => {
      let response = props.selectedData.imageTitle;
      return { name: response ? response : "" };
    });

    const uploadAvatar = () => {
  const image = props.selectedData.imageFile;

  if (image && image instanceof File) {

    const reader = new FileReader();
    reader.readAsDataURL(image);

    reader.onload = e => {
      if (e.target.result) {
        props.selectedData.imageTitle = image.name;
        props.selectedData.image = e.target.result;
      } else {
        console.error("Fehler beim Laden der Bilddatei");
      }
    };

    reader.onerror = () => {
      console.error("Fehler beim Lesen der Datei");
    };
  } else {
    console.error("Ungültige Bilddatei");
    props.selectedData.image = "";
    props.selectedData.imageTitle = "";
  }
};

    const closeDialog = () => {
      emit("update-expandBasicData", !props.dialog);
    };

    const selectDate = () => {
      menu.value = !menu.value;
    };

    const saveData = () => {
      store.dispatch("users/saveBasicData", { newBasicData: props.selectedData });
      emit("update-expandBasicData", !props.dialog);
    };

    return {
      menu,
      firstValid,
      requiredRules,
      lang,
      isEditable,
      birthday,
      today,
      getImageTitle,
      uploadAvatar,
      closeDialog,
      selectDate,
      saveData
    };
  }
};
</script>

<style>
.uploading-image {
  display: flex;
  max-height: 300px;
  max-width: 300px;
}

.flex {
  display: flex;
}

.right label { order: 1; }
.right input { order: 2; }
</style>
