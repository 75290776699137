import {publicationService} from '../services';

const state = {
    all: {},
    publications: {},
    publication: {},
    user: {},
    downloading: false,
};

const actions = {
    publicationLogin({commit},data){
        commit('setLoading',true);
        publicationService.publicationLogin(data).then(
            user => {
                commit('loginSuccess',user);
                let header = { 
                    'Content-Type': 'application/json', 
                    'Authorization': 'Bearer ' + user.token };
                publicationService.loginData(data.username,header).then(
                    data => {
                        commit('setLoading',false);
                        commit('publicationSuccess',data);
                    },
                    error => {
                        commit('requestFailure', error);
                        commit('setLoading', false);
                    }
                );
            }
        )
    },
    listPublication({commit}){
        commit('setLoading',true);
        publicationService.list().then(
            data => {
                commit('setLoading', false);
                commit('listSuccess',data);
                
            },
            error => {
                commit('requestFailure', error);
                commit('setLoading', false);
            }
        );
    },
    delete({commit},data){
      commit('setLoading', true);
      if(data.id){
        publicationService.deletePublication(data).then(
            success => {
                commit('deleteTemplate',success,data);
                publicationService.list().then(
                    data => {
                        commit('listSuccess',data);
                        commit('setLoading', false);
                    },
                    error => {
                        commit('requestFailure', error);
                        commit('setLoading', false);
                    }
                );
            },
            error => {
                commit('requestFailure', error);
            }
        );
      }
    },
    publish({commit},data){
        commit('setLoading',true);
        publicationService.save(data).then(
            success => {
                commit('setLoading', false);
                commit('requestSuccess',success);
            },
            error => {
                commit('requestFailure', error);
                commit('setLoading', false);
            }
        );
    },
    publicationData({dispatch,commit},token){
        commit('setLoading',true);
        publicationService.data(token).then(
            data => {
                commit('setLoading',false);
                commit('publicationSuccess',data);
            },
            error => {
                commit('requestFailure', error);
                commit('setLoading', false);
                dispatch('alert/error', error, { root: true });
            }
        );
    },
    download({dispatch, commit}, data) {
        commit('setDownloading', true);
        publicationService.download(data).then(
            () => {
                commit('setDownloading', false);
            },
            error => {
                commit('setDownloading', false);
                dispatch('alert/error', error, { root: true });
            }
        );
    }
}

const mutations = {
    requestFailure(state, error) {
        state.all = { error };
    },
    setLoading(state, payload) {
        state.all.loading = payload ;
    },
    requestSuccess(event){
        state.all.loading = event.all.loading ;
        
    },
    publicationSuccess(state,data){
        state.publication = data;
        
    },
    listSuccess(state, data){
        state.publications = data;
    },
    loginSuccess(user){
        state.loggedIn = true;
        state.user = user;
        console.log(user);
    },
    setDownloading(state, active) {
        state.downloading = active;
    }
}

export const publication = {
    namespaced: true,
    state,
    actions,
    mutations
  };