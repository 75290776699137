export default {
  "i18n_master_my_profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profil"])},
  "i18n_master_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
  "i18n_master_roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "i18n_master_experts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search expert"])},
  "i18n_master_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "profil": {
    "de": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateieingabe"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss"])},
      "study_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachbereich"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkungen"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtfeld"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufsbezeichnung"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
      "telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "basicdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundinformationen"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
      "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachen"])},
      "my_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Sprachen"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildung"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausbildung und/oder Studium"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berufserfahrung"])},
      "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
      "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektziel"])},
      "task_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgaben"])},
      "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branche"])},
      "team_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamgröße"])},
      "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikate"])},
      "certificate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifizierung/Qualifikation"])},
      "issued_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herausgegeben von"])},
      "valid_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erworben am"])},
      "section_my_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Skills"])},
      "msg_delete_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie dieses Element wirklich löschen?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
      "expert_opt_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional Skills"])},
      "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage"])},
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfallsdatum"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlichen"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
      "advanced_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Suche"])},
      "global_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale Suche"])},
      "expert_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experten Suche"])},
      "expert_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branche"])},
      "expert_must_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musst Skill"])},
      "expert_must_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musst Sprachen"])},
      "expert_opt_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Sprachen"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
      "reset_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtereingaben zurücksetzen"])},
      "external": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externer Mitarbeiter"])}
    },
    "en": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
      "degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degree"])},
      "study_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study field"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
      "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
      "telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "basicdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
      "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
      "my_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My languages"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
      "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School or University"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
      "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
      "job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Goal"])},
      "task_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
      "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
      "branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
      "team_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team size"])},
      "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifications & Trainings"])},
      "certificate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification/Qualification"])},
      "issued_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issued by"])},
      "valid_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid from"])},
      "section_my_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My skills"])},
      "msg_delete_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this item?"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "expert_opt_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional skills"])},
      "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
      "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "advanced_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced search"])},
      "global_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global search"])},
      "expert_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search expert"])},
      "expert_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
      "expert_must_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musst skill"])},
      "expert_must_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musst languages"])},
      "expert_opt_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional languages"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reset"])},
      "reset_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filter"])},
      "external": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External"])}
    }
  },
  "i18n_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "i18n_pflichtfeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
  "i18n_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
  "i18n_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "i18n_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "i18n_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "i18n_section_my_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My languages"])},
  "i18n_section_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School or University"])},
  "i18n_section_study_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Study field"])},
  "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
  "i18n_section_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
  "i18n_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "i18n_ende": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
  "i18n_section_remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
  "i18n_section_organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
  "i18n_section_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
  "i18n_section_job_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job description"])},
  "i18n_section_task_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
  "i18n_section_certificate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification/Qualification"])},
  "i18n_section_issued_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issued by"])},
  "i18n_section_valid_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid from"])},
  "i18n_section_my_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Skills"])},
  "i18n_section_formation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation"])},
  "i18n_section_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
  "i18n_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "i18n_user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "i18n_user_roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User roles"])},
  "i18n_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "i18n_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rights"])},
  "i18n_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "i18n_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "i18n_edit_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit role"])},
  "i18n_role_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role name"])},
  "i18n_privilegien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privileges"])},
  "i18n_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "i18n_edit_role_user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Edit Role from ", _interpolate(_named("name"))])},
  "i18n_msg_delete_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to remove this element?"])},
  "i18n_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "i18n_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "i18n_expert_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search expert"])},
  "i18n_expert_firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
  "i18n_expert_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
  "i18n_expert_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "i18n_expert_branch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
  "i18n_expert_must_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must skill"])},
  "i18n_expert_must_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must language"])},
  "i18n_expert_opt_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional skill"])},
  "i18n_expert_opt_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional language"])},
  "i18n_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
  "i18n_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "i18n_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "i18n_conf_skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
  "i18n_conf_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill"])},
  "i18n_conf_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
  "i18n_conf_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "i18n_conf_subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcategory"])},
  "i18n_conf_ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings"])},
  "i18n_conf_rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
  "i18n_conf_relevance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevance"])},
  "i18n_conf_max_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max value"])},
  "i18n_conf_rating_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating mode"])},
  "i18n_conf_layer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layer"])},
  "i18n_conf_parent_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Category"])},
  "i18n_conf_edit_skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit skill"])},
  "i18n_conf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "i18n_conf_edit_rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Rating"])},
  "i18n_conf_rating_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating name"])},
  "i18n_conf_max_scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max scale"])},
  "i18n_conf_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representation color"])},
  "i18n_conf_edit_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit language"])},
  "i18n_conf_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "i18n_conf_edit_classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit classification"])},
  "i18n_conf_classification_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification name"])},
  "i18n_field_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required"])},
  "i18n_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "i18n_old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
  "i18n_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "i18n_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "i18n_username_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username required"])},
  "i18n_password_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password required"])},
  "i18n_repeat_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
  "i18n_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "i18n_register_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Form"])},
  "i18n_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "i18n_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "i18n_change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "i18n_text_language_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language code"])},
  "i18n_text_translations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translations"])},
  "i18n_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
  "i18n_relevance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevance"])},
  "i18n_forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "i18n_send_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email"])},
  "i18n_reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "i18n_tocken_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tocken Expired"])},
  "i18n_web_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Templates"])},
  "i18n_print_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Templates"])},
  "i18n_new_print_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New print template"])},
  "i18n_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "i18n_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "i18n_template_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template file"])},
  "i18n_lang_de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "i18n_lang_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "i18n_parameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
  "i18n_download_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download profile"])},
  "i18n_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "i18n_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
  "i18n_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
  "i18n_qualification_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualification type"])},
  "i18n_CERTIFICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
  "i18n_TRAINING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
  "i18n_cert_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL of the  certificate"])},
  "i18n_date_time_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en-US"])},
  "i18n_external_true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External"])},
  "i18n_external_false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal"])},
  "i18n_external_both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both"])},
  "i18n_contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "i18n_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "i18n_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "i18n_edit_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit contact"])},
  "i18n_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])}
}