import { authHeader, hostUrl } from '../router';
import { handleResponse } from "../utils/service.utils";

export const contactService = {
    list, save, get, del
}

function list(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(hostUrl() + `/contact`, requestOptions).then(handleResponse);
}
function save(data){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(hostUrl() + `/contact`, requestOptions).then(handleResponse);
}
function get(data){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(hostUrl() + `/contact/` + data.id, requestOptions).then(handleResponse);
}
function del(data){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(data.id)
    };
    return fetch(hostUrl() + `/contact`, requestOptions).then(handleResponse);
}
