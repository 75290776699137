<template>
  <v-data-table
    :headers="skillHeader"
    :items="skills"
    :search="search"
    :footer-props="{itemsPerPageText: 'Zeilen pro Seite:'}"
    item-key="skill"
    style="margin-top: 10px"
    >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{$t('i18n_conf_skills')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('i18n_search')"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <DeleteWarning :dialog="deleteDialog" @fire-no="cancelDelete" @fire-yes="sendDelete"/>
        <TranslateSkill :dialog="translateDialog" @fire-close="closeTranslate"/>
        <EditConfigSkills :toEdit="newSkill" :dialog="dialog" @fire-save="save" @fire-close="initEdit"/>
      </v-toolbar>
    </template>
    <!-- <template v-slot:item.classificationsList="{ header, value }">
      <div 
        v-for="element in getClassifications(header, value)"
        :key="element.id">
        <v-list-item-subtitle>
          {{element.name}}
        </v-list-item-subtitle>
      </div>
    </template> -->
    <template v-slot:item.action="{ item }">
      <div class="align-center justify-space-between">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pen
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
        <v-icon
          small
          @click="translateItem(item)"
        >
          mdi-translate
        </v-icon>
        </div>
    </template>
  </v-data-table>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  
  import EditConfigSkills from '../customDialogs/EditConfigSkills.vue';
  import DeleteWarning from '../customDialogs/DeleteWarning.vue';
  import TranslateSkill from '../customDialogs/translate/TranslateSkill.vue';

  export default {
    data: () => ({
      currentItem:null,
      dialog:false,
      deleteDialog:false,
      translateDialog: false,
      skillId:null,
      search:"",
      newSkill: {
          id:null,
          classificationsList:[],
          code: null,
          name:"",
          rating:null,
          relevanz:"A",
          description: ""
        }
    }),
    computed: {
      ...mapState({
        skills: state => state.configuration.skills,
        classifications: state => state.configuration.classifications,
        isEditable: state => state.general.selectedPageEditable
      }),
      skillHeader() {
        let columns = [
          {
            title: this.$t('i18n_conf_skills'),
            align: 'left',
            sortable: false,
            value: 'name',
          },
          {
            title: this.$t('i18n_conf_relevance'),
            value: 'relevance',
            sortable: true
          },
          {
            title: this.$t('i18n_conf_rating'),
            value: 'rating.skaleName',
            sortable: true
          },
          {
            title: this.$t('i18n_conf_categories'),
            value: 'classification.parent.name',
            sortable: true
          },
          {
            title: this.$t('i18n_conf_subcategory'),
            value: 'classification.name',
            sortable: true
          }
        ];
        // let maxLayer = Math.max.apply(Math, this.classifications.map(function(o) { return o.layer; }));
        // for (let i = 1; i <= maxLayer; i++) {
        //   let name;
        //   if(i === 1) {
        //     name = this.$t('i18n_conf_category');
        //   } else if (maxLayer === 2) {
        //     name = this.$t('i18n_conf_subcategory')
        //   } else {
        //     name = this.$t('i18n_conf_subcategory') + (i - 1);
        //   }
        //   columns.push({
        //     title: name,
        //     value: 'classificationsList',
        //     layer: i
        //   });
        // }
        if(this.isEditable) {
          columns.push({ title: this.$t('i18n_actions'), value: 'action', sortable: false });
        }
       
        return columns;
      },
    },
    methods: {
      ...mapActions('configuration', ['saveSkill', 'deleteSkill', 'getTexts']),
      save(toSave) {
        this.saveSkill(toSave);
        this.initEdit(false);
      },
      editItem (item) {
        this.dialog = true;
        this.newSkill = JSON.parse(JSON.stringify(item)); 
      },

      deleteItem (item) {
        this.currentItem = item;
        this.deleteDialog = true;
      },
      translateItem (item) {
        this.translateDialog = true;
        this.getTexts({tableName: "skills", id: item.id});
      },
      closeTranslate() {
        this.skillId = null;
        this.translateDialog = false;
      },
      sendDelete() {
        this.deleteSkill(this.currentItem);
        this.deleteDialog = false;
      },
      cancelDelete() {
        this.currentItem = null;
        this.deleteDialog = false;
      },
      initEdit(isOpen) {
        this.newSkill = {
          id:null,
          classificationsList:[],
          code: this.$i18n.locale,
          name:"",
          rating: null,
          relevanz:"A",
          description: ""
        };
        this.dialog = isOpen;
      },
      getClassifications(header, value) {
        let result = [];
        this.mFindClassifications(value, header.layer, result);
        return result;
      },
      mFindClassifications(aValue, layer, result) {
        for (let index in aValue) {
          let element = aValue[index];
          if(element.layer > layer) {
            this.mFindClassifications([element.parent], layer, result);
          } else if (element.layer === layer) {
            let found = result.find(p => p.name === element.name);
            if (null == found) {
              result.push(element);
            }
          }
        }
        return result;
      }
    },
    components: {
        EditConfigSkills,
        DeleteWarning,
        TranslateSkill
    }
  }
</script>
