<template>
  <v-container>
    <v-card class="view">
      <v-card-title>
        <h3>{{$t('i18n_master_template')}}</h3>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-tabs
            v-model="currentItem"
            fixed-tabs
            bg-color="#3f51b5"
            class="custom-tabs"
            dark>
            <v-tab color="white">
              {{$t('i18n_print_template')}}
            </v-tab>
            <v-tab color="white">
              {{$t('i18n_web_template')}}
            </v-tab>
            <v-tab color="white">
              {{$t('i18n_contacts')}}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="currentItem">
            <v-tab-item>
              <TabPrintTemplate v-if="currentItem === 0"/>
            </v-tab-item>
            <v-tab-item>
              <TabWebTemplate v-if="currentItem === 1"/>
            </v-tab-item>
            <v-tab-item>
              <TabContacts v-if="currentItem === 2"/>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import TabWebTemplate from '../components/tabs/TabWebTemplate.vue';
  import TabPrintTemplate from "../components/tabs/TabPrintTemplate.vue";
  import TabContacts from '../components/tabs/TabContacts.vue';

  export default {
    data: () => ({
      currentItem:null
    }),
    components: {
      TabWebTemplate,
      TabPrintTemplate,
      TabContacts
    },
  }
</script>

<style scoped>
  .view {
    width:90%;
    height:90%;
  }
  .custom-tabs .v-tab {
    color: #b4b4b4;
  }
</style>
