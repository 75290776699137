import { userService } from '../services';
import { router } from '../router';

const user = JSON.parse(localStorage.getItem('user'));
const state = user
    ? { status: { loggedIn: true}, user, tockenExists: false, myPrivileges: {} }
    : { status: {}, tockenExists: false, user: null, myPrivileges: {} };

const actions = {
    login({ dispatch, commit }, { username, password, language }) {
        commit('loginRequest', { username });
    
        userService.login(username, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push({ path: '/home', query: { lang: language } });
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    logout({ commit }) {
        userService.logout();
        commit('logout');
        router.push({ path: '/login'});
    },
    changePassword({ dispatch, commit }, data) {
      userService.changePassword(data).then(
        () => {
          setTimeout(() => {
            // display success message after route change completes
            dispatch('alert/success', 'Change password successful', { root: true });
          })
        },
        error => {
            commit('registerFailure', error);
            dispatch('alert/error', error, { root: true });
        }
      );
    },
    retrievePassword({ dispatch, commit }, email) {
      userService.retrievePassword(email).then(
        () => {
          setTimeout(() => {
            // display success message after route change completes
            dispatch('alert/success', 'Email sended', { root: true });
            router.push({ path: '/home'});
          })
        },
        error => {
            commit('registerFailure', error);
            dispatch('alert/error', error, { root: true });
        }
      );
    },
    resetPassword({ dispatch, commit }, newPassword) {
      userService.resetPassword(newPassword).then(
        () => {
          setTimeout(() => {
            // display success message after route change completes
            dispatch('alert/success', 'Password reseted', { root: true });
            router.push({ path: '/home'});
          })
        },
        error => {
            commit('registerFailure', error);
            dispatch('alert/error', error, { root: true });
        }
      );
    },
    checkTocken({ dispatch, commit }, tocken) {
      userService.checkTocken(tocken).then(
        exists => {
          commit('checkTockenSuccess', exists)
        },
        error => {
            commit('registerFailure', error);
            dispatch('alert/error', error, { root: true });
        }
      );
    },
    getMyPrivileges({ dispatch, commit }) {
      userService.getMyPrivileges()
        .then(
          privileges => {
              commit('getMyPrivilegesSuccess', privileges);
          },
          error => {
              commit('registerFailure', error);
              dispatch('alert/error', error, { root: true });
          }
      );
    },
    register({ dispatch, commit }, user) {
        commit('registerRequest', user);
    
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    router.push('/login');
                    setTimeout(() => {
                        // display success message after route change completes
                        dispatch('alert/success', 'Registration successful', { root: true });
                    })
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    }
};

const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    },
    getMyPrivilegesSuccess(state, privileges) {
      state.myPrivileges = privileges;
    },
    checkTockenSuccess(state, exists) {
      state.tockenExists = exists;
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};
