import { authHeader, hostUrl } from '../router';
import { handleResponse, handleLogout } from "../utils/service.utils";

export const userService = {
    getAll,
    login,
    logout,
    changePassword,
    retrievePassword,
    resetPassword,
    checkTocken,
    getMyPrivileges,
    register,
    getById,
    getAvailableSkills,
    getLanguages,
    saveBasicData,
    saveEducation,
    deleteEducation,
    saveExperience,
    deleteExperience,
    saveCertificate,
    deleteCertificate,
    saveMySkills,
    deleteMySkills,
    saveMyLanguages,
    deleteMyLanguages,
    saveProjects,
    deleteProject,
    deleteUser
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(hostUrl() + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function logout() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(hostUrl() + `/logout`, requestOptions).then(handleLogout);
}

function changePassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(hostUrl() + `/changePassword`, requestOptions).then(handleResponse);
}

function retrievePassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(hostUrl() + `/retrievePassword`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(hostUrl() + `/register`, requestOptions).then(handleResponse);
}

function checkTocken(tocken) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(hostUrl() + `/checkTocken/${tocken}`, requestOptions).then(handleResponse);
}

function resetPassword(newPassword) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newPassword)
    };

    return fetch(hostUrl() + `/resetPassword`, requestOptions).then(handleResponse);
}

function getMyPrivileges() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(hostUrl() + `/getMyPrivileges`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(hostUrl() + `/all`, requestOptions).then(handleResponse);
}

function getById(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(hostUrl() + `/myProfil?userid=${data.userId}&&code=${data.language}`, requestOptions).then(handleResponse);
}

function getAvailableSkills(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(hostUrl() + `/getMyAvailableSkills?userid=${data.userID}&classificationid=${data.classificationID}&code=${data.code}`, requestOptions).then(handleResponse);
}

function getLanguages(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(hostUrl() + `/getLanguages?code=${data}`, requestOptions).then(handleResponse);
}

function saveBasicData(basicData) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(basicData.newBasicData)
    };

    return fetch(hostUrl() + `/saveBasicData`, requestOptions).then(handleResponse);
}

function saveEducation(basicData) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(basicData.newEducation)
    };

    return fetch(hostUrl() + `/saveEducation`, requestOptions).then(handleResponse);
}

function saveExperience(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data.newExperience)
    };

    return fetch(hostUrl() + `/saveExperience`, requestOptions).then(handleResponse);
}

function saveCertificate(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data.newCertificate)
    };

    return fetch(hostUrl() + `/saveCertificate`, requestOptions).then(handleResponse);
}

function saveProjects(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data.newProject)
    };

    return fetch(hostUrl() + `/saveProject`, requestOptions).then(handleResponse);
}

function saveMySkills(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(hostUrl() + `/saveMySkills`, requestOptions).then(handleResponse);
}

function saveMyLanguages(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data.newMyLanguages)
    };

    return fetch(hostUrl() + `/saveMyLanguages`, requestOptions).then(handleResponse);
}

function deleteEducation(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(data.id)
    };

    return fetch(hostUrl() + `/deleteEducation`, requestOptions).then(handleResponse);
}

function deleteExperience(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(data.id)
    };

    return fetch(hostUrl() + `/deleteExperience`, requestOptions).then(handleResponse);
}

function deleteCertificate(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(data.id)
    };

    return fetch(hostUrl() + `/deleteCertificate`, requestOptions).then(handleResponse);
}

function deleteMySkills(myClassification) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(myClassification)
    };

    return fetch(hostUrl() + `/deleteMySkills`, requestOptions).then(handleResponse);
}

function deleteMyLanguages(myLanguages) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(myLanguages)
    };

    return fetch(hostUrl() + `/deleteMyLanguages`, requestOptions).then(handleResponse);
}

function deleteProject(myProject) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(myProject.id)
    };

    return fetch(hostUrl() + `/deleteProject`, requestOptions).then(handleResponse);
}

function deleteUser(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: id
    };

    return fetch(hostUrl() + `/deleteUser`, requestOptions).then(handleResponse);
}
