<template>
  <div class="text-center">
    <v-dialog
      :model-value="dialog"
      @click:outside="closeDialog"
      width="800"
    >
      <v-card v-if="expert?.userDto">
        <v-card-text>
          <v-row align="start">
            <v-col cols="12" md="4" sm="3">
              <v-avatar class="profile" color="grey" size="164" tile>
                <v-img :src="expert.userDto.image"></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="12" md="8" sm="6">
              <v-list-item>
                  <v-list-item-title>{{ expert.userDto.firstname  }} {{ expert.userDto.lastname }}</v-list-item-title>
                  <v-list-item-subtitle>{{ expert.userDto.degree }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ expert.userDto.position }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ formatDate(expert.userDto.birthday) }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ expert.userDto.phone }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ expert.userDto.email }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ expert.userDto.address }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ expert.userDto.summary }}</v-list-item-subtitle>
              </v-list-item>
            </v-col>
          </v-row>
          <div v-for="item in expert.simpleClassification" :key="item.id">
            <h4>{{ item.classification.name }}</h4>
            <ChipsWithTooltips :items="sortSkillsByLevel(item.skills)" />
          </div>
          <div>
            <h4>{{ $t('i18n_languages') }}</h4>
            <ChipsWithTooltips :items="sortSkillsByLevel(expert.userLanguageDto || [])" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue';
import ChipsWithTooltips from "../customItems/ChipsWithTooltips";
import { sortSkillsByLevel } from "../../utils/profilePage.utils";

export default {
  components: { ChipsWithTooltips },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    expert: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const flag = ref(false);

    const closeDialog = () => {
      emit("closeDetailDialog", !props.dialog);
    };

    const formatDate = (date) => {
      if (date) {
        return new Date(date).toISOString().substr(0, 10);
      }
      return "";
    };

    return {
      flag,
      closeDialog,
      formatDate,
      sortSkillsByLevel
    };
  }
};
</script>
