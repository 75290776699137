<template>
   <v-dialog :model-value="dialog" max-width="500px">
      <v-card>
         <v-card-title>
            <span class="headline">{{$t('i18n_edit_contact')}}</span>
         </v-card-title>
         <v-card-text>
            <v-container>
               <v-form
                  ref="contactForm"
                  v-model="formValid">
                  <v-row>
                     <v-col cols="12">
                        <v-text-field
                           :label="$t('i18n_name')  + '*'"
                           :rules="roleFormRules"
                           v-model="contact.name">
                        </v-text-field>
                        <v-text-field
                           :label="$t('i18n_title')"
                           v-model="contact.title">
                        </v-text-field>
                        <v-text-field
                           :label="$t('i18n_email')  + '*'"
                           :rules="roleFormRules"
                           v-model="contact.mail">
                        </v-text-field>
                        <v-text-field
                           :label="$t('i18n_phone')  + '*'"
                           :rules="roleFormRules"
                           v-model="contact.phone">
                        </v-text-field>
                     </v-col>
                  </v-row>
               </v-form>
            </v-container>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error"  @click="close">{{$t('i18n_cancel')}}</v-btn>
            <v-btn :disabled="!formValid" color="primary" @click="save">{{$t('i18n_save')}}</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
   import { mapState } from "vuex";

   export default {
      data() {
         return {
            roleFormRules: [
                  v => !!v || "Field is required"
            ],
            formValid: true,
         }
      },
      props: {
         contact: Object,
         dialog: Boolean
      },
      computed: {
         ...mapState({
            loading: state => state.rolesAndPrivileges.all.loading,
            allowedPrivileges: state => state.rolesAndPrivileges.privileges,
            isEditable: state => state.general.selectedPageEditable
         })
      },
      methods: {
         close() {
            this.$emit("close", !this.dialog);
         },
         save() {
            this.$emit("save");
         }
      }
   }
</script>

<style>
   .no-top-and-bottom-padding {
      padding-bottom: 0;
      padding-top: 0;
   }
</style>
