import { authHeader, hostUrl } from '../router';
import { handleResponse, handleDownload } from "../utils/service.utils";

export const publicationService = {
    save, data, list, publicationLogin, loginData, deletePublication, download
}
function publicationLogin(data){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(hostUrl() + `/login/publication`, requestOptions).then(user => {
        // login successful if there's a jwt token in the response
        
        if (user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
        }

        return user;
    }).then(handleResponse);
}
function save(data){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(hostUrl() + `/publication`, requestOptions).then(handleResponse);
}
function data(token){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(hostUrl() + `/publication/data/${token}`, requestOptions).then(handleResponse);
    
}
function loginData(token,headers){
    const requestOptions = {
        method: 'GET',
        headers: headers,
    };
    return fetch(hostUrl() + `/publication/data/${token}`, requestOptions).then(handleResponse);
}
function list(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(hostUrl() + `/publication`, requestOptions).then(handleResponse);
}
function deletePublication(data){
  const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
  };
  return fetch(hostUrl() + `/publication/`+data.id, requestOptions).then(handleResponse);
}
function download(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(hostUrl() + `/print/profile`, requestOptions).then(handleDownload);
}
