<template>
  <v-card class="configCard">
    <v-card-title class="d-flex align-center justify-space-between">
      #{{config.id}} {{config.name}} 
      <v-spacer></v-spacer> 
      <v-menu location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            v-bind="props">
            <v-icon>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="deleteConfig()">
            <v-list-item-title>
              Delete
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="editConfig()">
            <v-list-item-title>
              Edit
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text>
      {{config.description}}
    </v-card-text>
  </v-card>
</template>
 
<script>
export default {
    name: 'PrintConfig',
    props:{
        config: Object
    },
    methods: {
        deleteConfig() {
            this.$emit("delete", this.config);
        },
        editConfig() {
            this.$emit("edit", this.config);
        },
    }
}
</script>

<style>
.configCard {
    height: 100%
}
.v-list-item__title {
    margin-bottom: 13px !important;
}
.menu-list { 
    margin-left: 140px;
    margin-top: -10px; 
    width: 100px;
}
.v-chip{
    margin:4px;
}
@media print{
    v-main{
        padding:0px;
    }
    .v-timeline {
        padding-top:22px;
    }
}
</style>
