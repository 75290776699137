<template>
<v-container class="content" >
    <v-app-bar
      app
      color="white"
    >
      <v-spacer></v-spacer>
      <v-img src="../assets/logo_aqs.png" contain apsect-ration="1.7" max-height="64" max-width="207"></v-img>
      <v-spacer></v-spacer>
      <span class="push">Version: {{ metaData.version }} </span>
    </v-app-bar>
    <v-main class="mx-auto login" >
      <v-card>
        <v-card-title>
            <h2>SkillDB</h2>
        </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
              >
                <v-text-field
                    v-model="username"
                    :rules="UsernameRules"
                    :label="$t('i18n_username')"
                    required
                    outlined
                    @keypress.enter="onSubmit">
                </v-text-field>
                <v-text-field
                    v-model="password"
                    type="password"
                    :rules="PasswordRules"
                    :label="$t('i18n_password')"
                    required
                    outlined
                    @keypress.enter="onSubmit">
                </v-text-field>
              </v-form>
                <v-row>
                  <v-select v-model="$i18n.locale"
                    class="language"
                    :items = "langs"
                    append-icon=""
                    flat
                    variant="solo"
                    dense
                  ></v-select>
                  <v-btn  variant="plain" class="mr-4" small text to="/retrievePassword" color="blue">{{$t('i18n_forgot_password')}}</v-btn>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-container>
                <v-btn
                    :disabled="!valid"
                    color="success"
                    class="mr-4"
                    @click="onSubmit">
                    {{$t('i18n_login')}}
                </v-btn>
                <img v-show="status.loggingIn" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                <router-link to="/register" class="btn btn-link">{{$t('i18n_register')}}</router-link>
              </v-container>
            </v-card-actions>
      </v-card>
    </v-main>
</v-container>
    
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    data () {
        return {
            username: '',
            password: '',
            submitted: false,
            UsernameRules: [
                v => !!v || this.$t('i18n_username_required')
            ],
            PasswordRules: [
                v => !!v || this.$t('i18n_username_required')
            ],
            valid: true
        }
    },
    computed: {
        ...mapState({
          status: state=> state.account.status,
          langs: state => state.general.langs,
          metaData: state => state.meta.meta
      }),
    },
    created () {
        // reset login status
        this.getMetaData();
    },
    methods: {
        ...mapActions('account', ['login']),
        ...mapActions('meta', ['getMetaData']),
        onSubmit () {
            const { valid, username, password } = this;
            const language = this.$i18n.locale;
            if (valid && username && password) {
                this.login({ username, password, language })
            }
        }
    }
};
</script>
<style scoped>
.content {
      width: 100%;
      height: 100%;
    }
.login{
    width: 50%;
    height: 400px;
}
.container {
    align-content: center;
}
.language {
  width: 10%;
}
</style>
